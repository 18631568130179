html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: white;
  font-family: "Times new Roman";

}
body {  
  top: 4rem;
  position: relative;
  overflow-x: hidden; /* Hides horizontal scrollbar if content overflows */
}
a, li{
  list-style: none;
  text-decoration: none;
  color: inherit;
}