.header {
    position: relative;
    margin: -1rem 0 0 0;
  }
  .image-controller img{
    height: 100%;
    width: 100%;
  }

  
