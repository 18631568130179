.enroll{
    width: 100%;
}
.enroll-content{
    width: 100%;
    display: flex;
    margin: 10px 0;
    padding: 5px ;
    align-items: center;
    justify-content: center;
    background-color: lightblue;
}

.btn{
    padding: 0.2rem 2rem;
    font-size: 1.3rem;
}
.enroll-content p{
    margin: 0 1rem;
    font-size: 2rem;
}
.scrolling-text {
    background-color: rgb(220, 180, 243);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    padding: 5px;
}

.scrolling-text span {
    font-size: 1.8rem;
    display: inline-block;
    padding-left: 50%;
    animation: scroll-text 12s linear infinite;
}

@keyframes scroll-text {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}


@media (max-width:400px){
  .btn{
    padding: .1rem 1rem;
  }
  .enroll-content p{
    font-size: 1rem;
  }
}
/******************************************************************************************************************************************/

.enrollContainer{
    min-height: 100vh;
    width: 100%;
    position: absolute;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    background-color: black;
    
  }
  .bothContainer {
   
    margin: 2rem auto;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between; 
  }
  .enrollForm {
    width: 50%;
    float: left;
    margin: 20px 0;
  }
  
  .enrollForm div input {
    width: 100%;
    outline: none;
    border: none;
    border-radius: 0px;
    border-bottom: 2px solid whitesmoke;
    margin-bottom: 20px;
    font-size: 20px;
    background-color: black;
    color: white;
  }
  .enrollForm ::placeholder {
    color: whitesmoke;
    opacity: .5;
  }
  .enrollForm div button {
    width: 100%;
    background-color: whitesmoke;
    color: black;
    height: 4rem;
    border-radius: 0px;
    border: none;
    border-bottom: 2px solid black;
    font-size: 20px;
    text-align: justify;
  }
  .mainForm {
    display: flex;
    width: 40%;
    margin: auto;
    flex-direction: column;
    align-items: center;
    margin: 0 3rem;
  }

  @media (max-width: 720px) {
    .bothContainer{
      flex-direction: column-reverse;
    }
    .mainForm{
      width: 100%;
    }
    .enroll-content p{
      margin: 0 1rem;
      font-size: 1.1rem;
  }
  }