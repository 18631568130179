.header2 {
  position: relative;
  margin-top: 2rem;
}
.image-controller img{
  height: 100%;
  width: 100%;
  border-radius: 18px 17px 0 0;
  box-shadow: 3px -20px 16px -17px #939090;
}
